import { parseCookies } from "nookies";
import qs from "qs";
import getConfig from "next/config";
import APP_CONSTANTS from "../../../action-constants/app-actions";
import errorLogger from "../../../../app_configs/logger-service";
import axios from "../../../../app_configs/axios/base";
import gateway from "../../../../app_configs/axios/gateway";
import { fetchContentFromCMS } from "../common/common-api";
import { dateToDMY, getCacheHeaders } from "../../../utils/common";

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

const showRequestLogs = publicRuntimeConfig.SHOW_REQUESTS_LOGS;
const columbusPath = publicRuntimeConfig.COLUMBUS_API_VERSION;
const serverProductListAPIURL = serverRuntimeConfig.SERVER_PRODUCT_LIST_API_URL;
const clientProductListAPIURL = publicRuntimeConfig.CLIENT_PRODUCT_LIST_API_URL;
const moodyAPIVersion = publicRuntimeConfig.MOODY_API_VERSION;
const isServer = typeof window === "undefined";
const isDesktop = !isServer ? window.dSite : false;

const error = {
  config: {},
  response: {},
};
const mobileServerControlHost = serverRuntimeConfig.SERVER_CONTROL_HOST;
const desktopServerControlHost = serverRuntimeConfig.D_SERVER_CONTROL_HOST;
const clientSideAPIURL = publicRuntimeConfig.PRODUCT_URL;

/**
 * This function is used to get the Category Reviews Based on Category Id
 *
 * @param {object} root .
 * @param {string} root.customCategoryId it is categoryId
 * @param {string} root.domainId it is domainID
 * @returns {Array} Reviews data in case of no exception otherwise an empty array.
 */
export const fetchPLPReviews = async ({ customCategoryId, domainId }) => {
  const endPoint = `${moodyAPIVersion}category/reviews`;
  try {
    const response = await gateway.get(endPoint, {
      params: {
        categoryId: customCategoryId,
        domainId,
        size: 12,
      },
      headers: {
        "Content-type": "application/x-www-form-urlencoded",
      },
    });
    return response.data;
  } catch (err) {
    error.config.url = endPoint;
    error.response.status = `An Exception occurred while calling rel alt API => ${err}`;
    errorLogger(err, showRequestLogs);
    throw new Error(err);
  }
};

/**
 * This is used for fetching actually product list for the requested category
 * and it is called on server for the first time
 *
 * @param {object} options API properties
 * @param {object} options.options API parameters
 * @param {object} options.ctx page context
 * @param {boolean} options.setResHeaders set cache headers
 * @returns {object} Product List
 */
export const fetchProductListing = async ({ options, ctx, setResHeaders = false }) => {
  try {
    const { params } = options;
    const res = await gateway.get(`${columbusPath}productList`, {
      baseURL: serverProductListAPIURL,
      params: { ...params },
      headers: {
        "x-device-type": ctx?.req?.headers["x-device-type"] || (isDesktop ? "desktop" : "mobile"),
        "x-domain": ctx?.req?.headers["x-domain"],
        Cookie: ctx.req.headers.cookie || "",
      },
      withCredentials: true,
      paramsSerializer: () => qs.stringify({ ...options.params }, { arrayFormat: "repeat" }),
    });
    if (setResHeaders && res?.headers) {
      let headersToSet = getCacheHeaders(res.headers);
      ctx?.res?.set(headersToSet);

      if (res.data?.data?.length === 0) {
        const updatedHeaders = {
          ...res.headers,
          "cache-control": "no-cache, no-store",
        };
        headersToSet = getCacheHeaders(updatedHeaders);
        ctx?.res?.set(headersToSet);
      }
    }
    return res.data;
  } catch (err) {
    if (err.response) return { errorStatus: err.response?.status };
    if (err.code) return err.code;
    return err;
  }
};

/**
 * Fetches categories HTML data using content id
 *
 * @param  {string} contentId unique id to fetch categories data
 * @returns {string} stringified HTML data or error code
 */
export async function fetchClientSideContentDetails(contentId) {
  try {
    const res = await axios.get(`control/getContentIdDetails-rj?contentId=${contentId}`);
    return res.data;
  } catch (e) {
    if (e.code && e.code === APP_CONSTANTS.ECONNABORTED) return e.code;
    throw new Error(e);
  }
}

/**
 * This is used for fetching product list for the requested category on client side
 *
 * @param {object} options .
 * @returns {Array} Product List
 */
export const fetchProductListingOnClient = async (options) => {
  try {
    const { lastSelectedPincode: selectedPincode, lastSelectedDeliveryDate } = parseCookies();
    const optionsModified = options;

    if (selectedPincode && optionsModified.params.geoId === "india" && optionsModified.params?.pageType !== "search") {
      // page type check is for checking the search page

      if (!optionsModified?.params?.isOnScroll) {
        optionsModified.params.pincode = selectedPincode;
      }
      if (lastSelectedDeliveryDate && !optionsModified?.params?.isOnScroll) {
        optionsModified.params.deliveryDate = dateToDMY(new Date(lastSelectedDeliveryDate));
      }
      if (!lastSelectedDeliveryDate && !optionsModified?.params?.isOnScroll) {
        delete optionsModified.params.deliveryDate;
      }
      delete optionsModified?.params?.isOnScroll;
    }
    const response = await gateway.get(`${columbusPath}productList`, {
      baseURL: clientProductListAPIURL,
      params: { ...optionsModified.params },
      paramsSerializer: () => qs.stringify({ ...optionsModified.params }, { arrayFormat: "repeat" }),
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

/**
 * This function fetches Bread Crumbs for the Category
 *
 * @param {object} options .
 * @param {boolean} isMobile flag to check if the request is from mobile or not
 * @returns {object} breadcrumbs data or empty object when exception.
 */
export async function fetchBreadcrumbs(options, isMobile = true) {
  try {
    const res = await axios.get("control/getBreadcrumbsCategory", {
      baseURL: isMobile ? mobileServerControlHost : desktopServerControlHost,
      ...options,
    });
    return res.data;
  } catch (ex) {
    throw new Error(ex);
  }
}

/**
 * Called on Client Side to Get Earliest Delivery Dates for the products
 *
 * @param {string} query carries the current country id.
 * @returns {object} returns the earliest data with respect to the product ids.
 */
export async function fetchEarliestDeliveries(query) {
  try {
    const response = await axios.get(`control/getEarliestDeliveryDays?${query}`, {
      baseURL: clientSideAPIURL,
    });
    return response.data;
  } catch (ex) {
    throw new Error(ex);
  }
}
/**
 * Called on Client Side to Get Earliest Delivery Dates for the products from R2
 *
 * @param {string} query carries the current country id and pincode
 * @returns {object} returns the earliest data with respect to the product ids.
 */
export async function fetchEarliestDeliveriesFromR2(query) {
  try {
    const response = await axios.get(`${columbusPath}earliestDeliveryDates?${query}`, {
      baseURL: clientProductListAPIURL,
    });
    return response.data;
  } catch (ex) {
    throw new Error(ex);
  }
}

/**
 * This function is used to get content id details
 *
 * @param {object} options option for fetching search specific data.
 * @returns {object} content id details.
 */
export async function fetchContentSearchedList(options) {
  try {
    const res = await fetchContentFromCMS(options);
    return res;
  } catch (ex) {
    throw new Error(ex);
  }
}

/**
 * This function fetches Product description as per the ids
 *
 * @param {object} options .
 * @returns {object}  data for product summary.
 */
export async function getProductSummaryDetails(options) {
  try {
    const res = await gateway.get(`${columbusPath}products/product-spotlights`, {
      params: {
        lang: "en",
        productIds: `${options.join()}`,
      },
    });
    return res.data;
  } catch (ex) {
    throw new Error(ex);
  }
}

/**
 * It will give the productlist for requested search param
 *
 * @param {string} param search param
 * @returns {string} returns product list.
 */
export async function findProductCategory(param) {
  try {
    const res = await axios.get("control/getProductListForPlp", {
      params: param,
    });
    return res;
  } catch (ex) {
    throw new Error(ex);
  }
}

/**
 * This method gives the property value as per the property sent in params
 *
 * @param {object} payload - payload
 * @returns {object} returns the property value
 */
export async function getSystemPropertyValue(payload) {
  try {
    const { isMobile, ...params } = payload;
    const resp = await axios.get(`control/getSystemPropertyValue?${qs.stringify(params)}`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      baseURL: isMobile ? mobileServerControlHost : desktopServerControlHost,
      credentials: "same-origin",
    });
    return resp.data;
  } catch (err) {
    error.config.url = "control/getSystemPropertyValue";
    return {
      error: err.message,
    };
  }
}
