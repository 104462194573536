import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/core/styles";
import getConfig from "next/config";
import { useRouter } from "next/router";
import StickyCartPriceDetails from "./stickyCartPriceDetails";
import APP_CONSTANTS from "../../../src/action-constants/app-actions";
import EmptyCart from "./emptyCart";
import CartListing from "./cartListing";
import {
  updateViewCartJSON,
  setFnpPageType,
  FnpPageType,
  iswalletCalculate,
  getProductDeliveryType,
  processDeliveryDate,
} from "../../../src/utils/common";
import CartListingSkeleton from "./cartListingSkeleton";
import Loader from "../../mobile/plp/loader";
import useCartPreviewOmnitureSetup from "../../hooks/analytics/useCartPreviewOmnitureSetup";
import walletStyles from "../../common/styles/wallet-CheckBox.module.scss";
import WalletCheckBox from "../../common/walletCheckBox";
import useFNPWalletBalanceData from "../../hooks/analytics/useFNPWalletBalanceData";
import { cleverTapCommonEvents, cleverTapCommonWords } from "../../../src/analytics/clevertapConstants";
import callClevertapEvent from "../../../src/analytics/clevertapUtility";

const { publicRuntimeConfig } = getConfig();

const useStyles = makeStyles((theme) => ({
  loaderOverlay: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    zIndex: 1005,
    left: 0,
    top: 0,
    right: 0,
    overflowY: "hidden",
    backgroundColor: "rgba(10, 10, 10, 0.85)",
    backgroundPosition: "center",
  },
  cartListingContainer: { padding: "15px 0 1px" },
  desktopCartListingContainer: { padding: "16px", maxHeight: "330px", overflowY: "auto", overflowX: "hidden" },
  desktopLoaderOverlay: {
    left: 0,
    right: 0,
    bottom: 0,
    top: "53px",
    display: "flex",
    zIndex: 1005,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(10, 10, 10, 0.85)",
    backgroundPosition: "center",
    position: "absolute",
  },
  addMessage: {
    background: theme.color?.olive,
    padding: "5px",
    color: theme.color?.white,
    fontSize: "14px",
    textAlign: "left",
    borderRadius: "4px",
    marginBottom: "10px",
    lineHeight: "normal",
  },
  addMessageText: {
    verticalAlign: "super",
  },
  hide: {
    display: "none",
  },
}));

/**
 * This function in where all the cart component  has to be displayed on the cart page
 *
 * @param {object} param0 Component props
 * @param {Function} param0.handleProceedToAddon Function that will navigate to addon screen
 * @returns {React.ReactElement} jsx for the cart page.
 */
function CartPreview({ handleProceedToAddon, ...props }) {
  const router = useRouter();
  const classes = useStyles(props);
  const currencies = useSelector((store) => store.currenciesList.currencies || [], shallowEqual);
  const isMobile = useSelector((store) => store.appConfigs.isMobile);
  const { isOmnitureEnabled, isCdpEnabled } = useSelector((state) =>
    isMobile ? state.appConfigs.configData.mobile : state.appConfigs.configData.desktop,
  );
  const {
    cartSummary: cartData,
    isLoadingCartDetails: showCartSkeleton,
    isLoadingPncCard: showPncCartSkeleton,
    isLoadingAddonUpdate,
    isLoadingDeleteItem,
    firstTimeLoad,
    showAddItemToCart,
  } = useSelector((state) => state.cartDetails);
  const host = publicRuntimeConfig.D_HOST;
  const { checkLoginInfo: userDetails, cartInfo, fusCompleted } = useSelector((state) => state.userDetails);
  const fvId = useSelector((state) => state.userDetails.cartId);
  const dispatch = useDispatch();
  const localCurrency = useSelector((store) => store.appData.selectedCurrency);
  const [checked, setChecked] = useState(true);
  const [checkedFromSession, setCheckedFromSession] = useState(true);
  const usableBalance = useSelector((state) => state.walletDetails?.walletUsableBalance?.usableBalance) || 0;
  const [currentBalanceWallet, setCurrentBalanceWallet] = useState(0);
  const { amountUsed, availableBalance } = iswalletCalculate(cartData?.total, currentBalanceWallet);
  const [availableAmount, setAvailableAmount] = useState({ amountUse: amountUsed, availableBal: availableBalance });
  const [isEmptyCartCleverTap, setIsEmptyCartCleverTap] = useState(false);
  const amountInStickyBar = availableAmount?.amountUse;
  const loggedInUser = useSelector((state) => state.userDetails?.checkLoginInfo?.loggedin);
  const localCurrencyINR = localCurrency === "INR";
  const fnpWalletSelected = useSelector((state) => state.walletDetails?.fnpWalletSelected);

  useFNPWalletBalanceData();

  useEffect(() => {
    setCurrentBalanceWallet(usableBalance);
  }, [usableBalance]);

  useEffect(() => {
    setAvailableAmount({ amountUse: amountUsed, availableBal: availableBalance });
  }, [amountUsed, availableBalance]);

  useEffect(() => {
    if (isMobile || !firstTimeLoad) {
      dispatch({ type: APP_CONSTANTS.GET_CART_SUMMARY_INFO_REQUEST });
      setFnpPageType(FnpPageType.CART_PREVIEW);
    }
    // disabled because we require component did mount not for did update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCdpEnabled && window.cdp && fvId && cartData?.items?.length) {
      window.cdp.cartDetail(fvId);
    }
  }, [fvId, isCdpEnabled, isOmnitureEnabled, cartData]);

  useCartPreviewOmnitureSetup();

  const getCartDataFromLocalStorage =
    typeof window !== "undefined" && JSON.parse(window?.localStorage?.getItem("Cart"));
  const cartDataFromLocal = getCartDataFromLocalStorage?.data || {};
  const pricingSummary = cartData.pricingSummary || {};
  const additionalPromoAdjustment = cartData.additionalPromoAdjustment?.[0] || {};
  const items = cartData.items || [];

  const pageNameAndPageType = {
    page_name: cleverTapCommonWords.CART,
    page_type: cleverTapCommonWords.CART,
  };

  const cleverTapCartCommonData = {
    cart_value: cartDataFromLocal.total ? Number(cartDataFromLocal.total) : 0,
    total_discount: pricingSummary.couponDiscount ? pricingSummary.couponDiscount + amountUsed : amountUsed,
    total_delivery_fee: Number(cartDataFromLocal.total).totalShipping || 0,
    convenience_fee: additionalPromoAdjustment.additionalAmount ?? 0,
    total_product_count: items.length ?? 0,
    login_status: loggedInUser,
    is_cart_empty: items.length === 0,
    donation_amount: cartDataFromLocal.donationAmount ?? 0,
    coupon_discount: pricingSummary.couponDiscount ?? 0,
    ...pageNameAndPageType,
  };

  useEffect(() => {
    const additionalCartData = {
      ...cleverTapCartCommonData,
      fnp_cash_discount: undefined,
      complete_url: `${host}${router.asPath}`,
      clean_url: `${host}${router.asPath}`.split("?")[0],
    };
    if (isMobile) {
      callClevertapEvent(cleverTapCommonEvents.cartViewed, additionalCartData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      ((fusCompleted && isMobile && !cartData?.items?.length) || (!isMobile && !cartInfo?.cartTotalQuantity)) &&
      !isEmptyCartCleverTap
    ) {
      callClevertapEvent(cleverTapCommonEvents.cartEmpty, {
        cart_value: cartDataFromLocal.total ? Number(cartDataFromLocal.total) : 0,
        login_status: userDetails?.loggedin,
        ...pageNameAndPageType,
      });
      setIsEmptyCartCleverTap(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cartData.items,
    cartDataFromLocal.total,
    cartInfo.cartTotalQuantity,
    isEmptyCartCleverTap,
    userDetails.loggedin,
    fusCompleted,
  ]);

  /**
   * This function dispatches an action to update addon quantity
   *
   * @param {object} param0 item you want to update
   * @param {string} param0.productId Id of item.
   * @param {number} param0.itemIndex Index of item.
   * @param {number} param0.quantity New quantity of item
   * @param {object} param0.giftItem all product details object
   * @param {string} param0.addOnName name of product
   * @param {number} param0.addOnPrice price of product
   * @param {boolean} param0.isAddonAdded check to addons added or removed
   */
  const handleQuantityUpdate = ({ quantity, itemIndex, productId, giftItem, addOnName, addOnPrice, isAddonAdded }) => {
    const deliverType = getProductDeliveryType(giftItem.primaryProductCategoryId);
    const deliveryDateFormat = giftItem?.shippingDetails?.deliveryDate?.fullDeliveryDate;
    if (isAddonAdded) {
      const cleverTapData = {
        product_name: giftItem.productName,
        pid: productId,
        product_category: giftItem.primaryProductCategoryId,
        product_price: giftItem.price,
        delivery_type: deliverType,
        pincode: giftItem.pincode,
        area: giftItem.deliveryLocation,
        system_pincode: giftItem.pincode,
        date_of_delivery: processDeliveryDate(deliveryDateFormat),
        delivery_shipping: giftItem.shippingMethodName,
        delivery_shipping_fee: giftItem.shippingDetails.shippingPrice,
        delivery_slot: giftItem.shippingDetails.deliveryTimeSlot,
        addon_name: addOnName,
        addon_price: addOnPrice,
        addon_quantity: quantity,
        is_international: giftItem.isIntlCatalog,
        ...pageNameAndPageType,
      };
      callClevertapEvent(cleverTapCommonEvents.addonAdded, cleverTapData);
    } else {
      const cleverTapData = {
        pid: productId,
        pincode: giftItem.pincode,
        product_name: giftItem.productName,
        product_category: giftItem.primaryProductCategoryId,
        product_price: giftItem.price,
        delivery_type: deliverType,
        date_of_delivery: processDeliveryDate(deliveryDateFormat),
        delivery_shipping: giftItem.shippingMethodName,
        delivery_shipping_fee: giftItem.shippingDetails.shippingPrice,
        delivery_slot: giftItem.shippingDetails.deliveryTimeSlot,
        addon_name: addOnName,
        addon_price: addOnPrice,
        addon_quantity: quantity,
        area: giftItem.deliveryLocation,
        system_pincode: giftItem.pincode,
        is_addon_added: giftItem.addons.length > 0,
        is_international: giftItem.isIntlCatalog,
        ...pageNameAndPageType,
      };
      callClevertapEvent(cleverTapCommonEvents.addonRemoved, cleverTapData);
    }
    const params = {
      isCart: true,
      itemIndex,
      productId_quantity: `${productId}_${quantity}`,
      qty: quantity,
    };
    dispatch({ type: APP_CONSTANTS.UPDATE_ADDON_QUANTITY_REQUEST, payload: params });
  };

  /**
   * handle checked and unchecked wallet balance change
   *
   * @param {any} newChecked parameter to be checked and unchecked
   */
  const handleChildStateChange = (newChecked) => {
    setChecked(newChecked);
    sessionStorage.setItem("walletCheckboxState", newChecked);
  };

  /**
   * Function to get the value from session storage and initialize the `checked` state
   *
   */
  const fetchCheckedFromSessionStorage = () => {
    const storedValue = sessionStorage.getItem("walletCheckboxState");
    setCheckedFromSession(
      localCurrencyINR && (storedValue === "true" || (storedValue === null && fnpWalletSelected === "Y")),
    );
  };

  useEffect(() => {
    fetchCheckedFromSessionStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localCurrencyINR, fnpWalletSelected]);

  useEffect(() => {
    setChecked(checkedFromSession);
  }, [checkedFromSession, localCurrencyINR]);

  /**
   * This function dispatches an action to delete cart item
   *
   * @param {object} params item you want to delete
   * @param {string} params.productId Id of item you want to delete
   * @param {number} params.index Index of item you want to delete
   * @param {string} params.productName - The name of the product.
   * @param {string} params.primaryProductCategoryId - The primary category ID of the product.
   * @param {number} params.price - The price of the product.
   * @param {string} params.fullDeliveryDate - The full delivery date of the product.
   * @param {string} params.shippingMethodName - The name of the shipping method.
   * @param {number} params.shippingPrice - The price of the shipping.
   * @param {string} params.deliveryTimeSlot - The delivery time slot.
   * @param {string} params.addOnsName - The name of the add-ons.
   * @param {number} params.unitPrice - The unit price of the add-ons.
   * @param {number} params.addOnsQuantity - The quantity of the add-ons.
   * @param {string} params.deliveryLocation - The delivery location.
   * @param {string} params.pincode - The pincode of the delivery location.
   * @param {number} params.addonsLength - The length of the add-ons array.
   * @param {boolean} params.isAddon - to check delete a addons or product.
   * @param {boolean} params.isIntlCatalog - to check its international or domestic.
   */
  const handleDeleteItem = ({
    productId,
    index,
    isAddon,
    productName,
    primaryProductCategoryId,
    price,
    fullDeliveryDate,
    shippingMethodName,
    shippingPrice,
    deliveryTimeSlot,
    addOnsName,
    unitPrice,
    addOnsQuantity,
    deliveryLocation,
    pincode,
    addonsLength,
    isIntlCatalog,
  }) => {
    const deliverType = getProductDeliveryType(primaryProductCategoryId);
    const cleverTapData = {
      pid: productId,
      pincode,
      product_name: productName,
      product_category: primaryProductCategoryId,
      product_price: price,
      delivery_type: deliverType,
      date_of_delivery: processDeliveryDate(fullDeliveryDate),
      delivery_shipping: shippingMethodName,
      delivery_shipping_fee: shippingPrice,
      delivery_slot: deliveryTimeSlot,
      addon_name: addOnsName,
      addon_price: unitPrice,
      addon_quantity: addOnsQuantity,
      area: deliveryLocation,
      system_pincode: pincode,
      is_addon_added: addonsLength > 0,
      is_international: isIntlCatalog,
      ...pageNameAndPageType,
    };

    if (isAddon) {
      callClevertapEvent(cleverTapCommonEvents.addonRemoved, cleverTapData);
    } else {
      callClevertapEvent(cleverTapCommonEvents.productRemoved, cleverTapData);
    }

    const params = {
      [`DELETE_${index}`]: index,
      productId,
      format: "json",
    };
    dispatch({ type: APP_CONSTANTS.DELETE_CART_ITEM_REQUEST, payload: params });
  };

  const CurrencyCheckBoxStyle = {
    opacity: localCurrencyINR ? 1 : 0.5,
  };

  if (
    (showCartSkeleton && !isLoadingAddonUpdate && !isLoadingDeleteItem) ||
    (showPncCartSkeleton && cartInfo?.cartTotalQuantity > 0)
  )
    return <CartListingSkeleton isMobile={isMobile} />;
  return (
    <>
      <div className={!showCartSkeleton ? "cart-container" : ""}>
        {(isMobile && !cartData?.items?.length) || (!isMobile && !cartInfo?.cartTotalQuantity) ? (
          <EmptyCart loggedIn={userDetails?.loggedin} isMobile={isMobile} />
        ) : (
          <>
            {isLoadingAddonUpdate || isLoadingDeleteItem ? (
              <div className={isMobile ? classes.loaderOverlay : classes.desktopLoaderOverlay}>
                <Loader />
              </div>
            ) : null}

            <Grid className={isMobile ? classes.cartListingContainer : classes.desktopCartListingContainer}>
              <div className={!showAddItemToCart && classes.hide}>
                <div className={classes.addMessage}>
                  <CheckCircleIcon />
                  <span id="add-message" className={classes.addMessageText}>
                    Item Added to Cart
                  </span>
                </div>
              </div>

              <CartListing
                isMobile={isMobile}
                cartData={updateViewCartJSON(cartData)}
                localCurrency={localCurrency}
                isMultiImageUploadOnVal
                updateQuantityAction={handleQuantityUpdate}
                deleteOrderItemAction={handleDeleteItem}
                currencies={currencies}
                handleProceedToAddon={handleProceedToAddon}
                cleverTapCartCommonData={cleverTapCartCommonData}
              />
              {loggedInUser && (
                <div className={walletStyles.cartWalletStyle}>
                  <WalletCheckBox
                    setAvailableAmount={setAvailableAmount}
                    onStateChange={handleChildStateChange}
                    availableAmount={availableAmount}
                    checked={checked}
                    disabled={!localCurrencyINR}
                    CurrencyCheckBoxStyle={CurrencyCheckBoxStyle}
                    cleverTapCartCommonData={cleverTapCartCommonData}
                  />
                </div>
              )}
            </Grid>
            <StickyCartPriceDetails
              isMobile={isMobile}
              priceInfo={updateViewCartJSON(cartData).paymentInfo}
              loggedIn={userDetails?.loggedin}
              localCurrency={localCurrency}
              currencies={currencies}
              availableAmountUsed={amountInStickyBar}
              checkBoxChecked={!checked}
              localCurrencyINR={localCurrencyINR}
              cleverTapCartCommonData={cleverTapCartCommonData}
            />
          </>
        )}
      </div>
    </>
  );
}
const propTypes = {
  handleProceedToAddon: PropTypes.func.isRequired,
};

CartPreview.propTypes = propTypes;

export default CartPreview;
