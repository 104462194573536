const ListingPageConstants = {
  SET_PRODUCTS_LISTING: "SET_PRODUCTS_LISTING",
  CLEAR_PRODUCTS_LISTING: "CLEAR_PRODUCTS_LISTING",
  SET_DYNAMIC_META_DATA: "SET_DYNAMIC_META_DATA",
  SET_BREAD_CRUMBS_DATA: "SET_BREAD_CRUMBS_DATA",
  SET_REL_ALTS_DATA: "SET_REL_ALTS_DATA",
  SET_CATEGORY_REVIEWS: "SET_CATEGORY_REVIEWS",
  SET_BANNER_DETAILS: "SET_BANNER_DETAILS",
  SORTING_PRODUCTS_LIST: "SORTING_PRODUCTS_LIST",
  SORTING_PRODUCTS_FAILED: "SORTING_PRODUCTS_FAILED",
  DELIVERY_DATES_POPULATED: "DELIVERY_DATES_POPULATED",
  CURRENCY_CHANGES_DONE: "CURRENCY_CHANGES_DONE",
  SET_LOCAL_SCHEMA_DATA: "SET_LOCAL_SCHEMA_DATA",
  SET_HTML_MICRO_SITE_DATA: "SET_HTML_MICRO_SITE_DATA",
  SET_PLP_SVN_BREAD_CRUMBS: "SET_PLP_SVN_BREAD_CRUMBS",
  PRODUCT_DESC_REQUEST: "PRODUCT_DESC_REQUEST",
  PRODUCT_DESC_SUCCESS: "PRODUCT_DESC_SUCCESS",
  PRODUCT_ERROR_MSG: "PRODUCT_ERROR_MSG",
  PRODUCT_LISTING_ERROR_MSG: "PRODUCT_LISTING_ERROR_MSG",
  GET_PRODUCT_LISTING_REQUEST: "GET_PRODUCT_LISTING_REQUEST",
  SET_SEARCH_INSTEAD_DATA: "SET_SEARCH_INSTEAD_DATA",
  GET_SEARCH_INSTEAD_DATA: "GET_SEARCH_INSTEAD_DATA",
  GET_PRODUCT_LISTING_SERVER_REQUEST: "GET_PRODUCT_LISTING_SERVER_REQUEST",
  SERVER_REQUEST_PLP_REVIEWS: "SERVER_REQUEST_PLP_REVIEWS",
  GET_BANNER_CATEGORY: "GET_BANNER_CATEGORY",
  REQUEST_GET_PRODUCT_LISTING: "REQUEST_GET_PRODUCT_LISTING",
  SERVER_REQUEST_CATEGORY_BREADCRUMBS: "SERVER_REQUEST_CATEGORY_BREADCRUMBS",
  FETCH_EARLIEST_DELIVERIES: "FETCH_EARLIEST_DELIVERIES",
  NOT_APPLICABLE: "_NA_",
  RECOMMENDED: "Recommended",
  PRODUCT_LISTING_LOAD_MORE_FAILED: "PRODUCT_LISTING_LOAD_MORE_FAILED",
  PLP_CMS_DATA_REQUEST: "PLP_CMS_DATA_REQUEST",
  SET_PLP_USP: "SET_PLP_USP",
  SET_PLP_LONG_DESCRIPTION: "SET_PLP_LONG_DESCRIPTION",
  SET_PLP_SHORT_DESCRIPTION: "SET_PLP_SHORT_DESCRIPTION",
  GET_PRODUCT_ATTRIBUTE_DATA: "GET_PRODUCT_ATTRIBUTE_DATA",
  SET_PRODUCT_ATTRIBUTE_DATA: "SET_PRODUCT_ATTRIBUTE_DATA",
  SET_PLP_MICRO_SITE_WIDGETS: "SET_PLP_MICRO_SITE_WIDGETS",
  GEO_CODE_INDIA: "IN",
  PAGE_TYPE: "search",
  REQUEST_PRODUCTS_ON_PLP: "REQUEST_PRODUCTS_ON_PLP",
  FETCH_ENABLE_ADD_TO_CART: "FETCH_ENABLE_ADD_TO_CART",
  SET_ENABLE_ADD_TO_CART: "SET_ENABLE_ADD_TO_CART",
  SET_PRODUCT_LISTING_LOADING: "SET_PRODUCT_LISTING_LOADING",
  SET_WIDGET_DATA: "REQUEST_WIDGET_DATA",
  GET_WIDGET_DATA: "GET_WIDGET_DATA",
  SET_SEARCH_REDIRECT_URL: "SET_SEARCH_REDIRECT_URL",
  SET_PLP_PROMOTIONAL_BANNERS: "SET_PLP_PROMOTIONAL_BANNERS",
  SET_CAPSULE_DATA_DETAILS: "SET_CAPSULE_DATA_DETAILS",
};

export default ListingPageConstants;
