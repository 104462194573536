import ListingPageConstants from "../action-constants/listing-contants";
import APP_CONSTANTS from "../action-constants/app-actions";

/**
 * Action for requesting PLP reviews
 *
 * @param {string} payload contains category ID
 * @returns {object} returns action
 */
export const requestPLPReviews = (payload) => ({
  type: ListingPageConstants.SERVER_REQUEST_PLP_REVIEWS,
  payload,
});

/**
 * Action for setting PLP reviews
 *
 * @param {string} payload contains review data
 * @returns {object} returns action
 */
export const setPLPReviews = (payload) => ({
  type: ListingPageConstants.SET_CATEGORY_REVIEWS,
  payload,
});

/**
 * Action for requesting product list  data
 *
 * @param {object} payload contains content options
 * @returns {object} returns action
 */
export const requestPlpListing = (payload) => ({
  type: ListingPageConstants.GET_PRODUCT_LISTING_SERVER_REQUEST,
  payload,
});

/**
 * actions for setting the svn data in reducer
 *
 * @param {object} payload contains svn data options
 * @returns {object} returns action
 */
export const setPlpHTMLData = (payload) => ({
  type: ListingPageConstants.SET_HTML_MICRO_SITE_DATA,
  payload,
});

/**
 * Action for plp error msg
 *
 * @param {object} error contains error msg
 * @returns {object} returns action
 */
export const plpErrorMSG = (error) => ({
  type: ListingPageConstants.PRODUCT_LISTING_ERROR_MSG,
  payload: error,
});

/**
 * Action for requesting banner category data
 *
 * @param {object} payload contains contentId
 * @returns {object} returns action
 */
export const requestBannerCategory = (payload) => ({
  type: ListingPageConstants.GET_BANNER_CATEGORY,
  payload,
});

/**
 * Action for requesting product list  data
 *
 * @param {object} payload contains content options
 * @returns {object} returns action
 */
export const requestPlpListingOnClient = (payload) => ({
  type: ListingPageConstants.REQUEST_GET_PRODUCT_LISTING,
  payload,
});

/**
 * Action for requesting to set product list loading
 *
 * @param {boolean} isLoading true/false of loading
 * @returns {object} returns action
 */
export const setPlpListingLoading = (isLoading) => ({
  type: ListingPageConstants.SET_PRODUCT_LISTING_LOADING,
  payload: isLoading,
});

/**
 * Action for setting banner category data
 *
 * @param {object} payload contains banner category data
 * @returns {object} returns action
 */
export const setBannerCategory = (payload) => ({
  type: ListingPageConstants.SET_BANNER_DETAILS,
  payload,
});

/**
 * Action for setting PLP capsule data.
 *
 * @param {object} payload contains banner category data
 * @returns {object} returns action
 */
export const setCapsuleData = (payload) => ({
  type: ListingPageConstants.SET_CAPSULE_DATA_DETAILS,
  payload,
});

/**
 * Action for setting product list data
 *
 * @param {object} payload contains product list data
 * @returns {object} returns action
 */
export const setProductList = (payload) => ({
  type: ListingPageConstants.SET_PRODUCTS_LISTING,
  payload,
});

/**
 * Populating Bread Crumbs data for Category to the store for showing on Desktop PLP Page
 *
 * @param {object} payload contains content options
 * @returns {object} returns action
 */
export const setBreadCrumbsData = (payload) => ({
  type: ListingPageConstants.SET_BREAD_CRUMBS_DATA,
  payload,
});

/**
 * Action for setting SVN Bread Crumbs data
 *
 * @param {object} payload contains content options
 * @returns {object} returns action
 */
export const setPlpSvnBreadCrumbs = (payload) => ({
  type: ListingPageConstants.SET_PLP_SVN_BREAD_CRUMBS,
  payload,
});

/**
 * Action for setting page name.
 *
 * @param {string} payload contains page name to update.
 * @returns {object} returns data to set page name.
 */
export const setPageName = (payload) => ({
  type: APP_CONSTANTS.SET_PAGE_NAME,
  payload,
});

/**
 * Action for starting sorting process
 *
 * @returns {object} returns action
 */
export const startProductListSorting = () => ({
  type: ListingPageConstants.SORTING_PRODUCTS_LIST,
});

/** Action of returning the dispatcher object for fetching the delivery dates.
 *
 * @param {payload} payload which contains pincode
 * @returns {object} contains dispatcher data for delivery dates.
 */
export const requestEarliestDeliveries = (payload) => ({
  type: ListingPageConstants.FETCH_EARLIEST_DELIVERIES,
  payload,
});

/**
 * Action of returning the dispatcher object for updating the product list with
 * earliest dates.
 *
 * @param {object} daysList list of products with earliest delivery days.
 * @param {object} dateList list of products with earliest delivery dates.
 * @returns {object} contains dispatcher data for update products.
 */
export const updateEarliestDeliveries = (daysList, dateList) => ({
  type: ListingPageConstants.DELIVERY_DATES_POPULATED,
  payload: {
    earliestDeliveryDays: daysList,
    earliestDeliveryDates: dateList,
  },
});

/**
 * Action for setting page name.
 *
 * @param {string} payload set content id details.
 * @returns {object} returns get content id details.
 */
export const setContentIdDetails = (payload) => ({
  type: ListingPageConstants.SET_SEARCH_INSTEAD_DATA,
  payload,
});

/**
 * Action for requesting and setting filter data
 *
 * @param {string} payload content id for the black strip filter
 * @returns {object} filter request action object
 */
export const requestFilterData = (payload) => ({
  type: APP_CONSTANTS.GET_FILTER_DATA_REQUEST,
  payload,
});

/**
 * Action for setting filer data success
 *
 * @param {string} payload response.
 * @returns {object} filter data success object
 */
export const setFilterSuccess = (payload) => ({
  type: APP_CONSTANTS.GET_FILTER_DATA_SUCCESS,
  payload,
});

/**
 * Action for requesting and setting usp data
 *
 * @param {string} payload response.
 * @returns {object} usp data request action object
 */
export const requestUspData = (payload) => ({
  type: APP_CONSTANTS.GET_USP_DATA_REQUEST,
  payload,
});

/**
 * Action for requesting product summary
 *
 * @returns {object} returns object of action to be dispatched.
 */
export const requestProductSummaryDetails = () => ({
  type: ListingPageConstants.PRODUCT_DESC_REQUEST,
});

/**
 * Action for setting product summary in reducer
 *
 * @param {object} data  data for product summary
 * @returns {object} returns object of action to be dispatched.
 */
export const setProductSummaryDetails = (data) => ({
  type: ListingPageConstants.PRODUCT_DESC_SUCCESS,
  payload: data,
});

/**
 * Action for clearing product listing reducer
 *
 * @returns {object} returns object of action to be dispatched.
 */
export const resetProductListing = () => ({
  type: ListingPageConstants.CLEAR_PRODUCTS_LISTING,
});

/**
 * Action for requesting plp cms data
 *
 * @param {object} contentOptions CMS content options
 * @returns {object} returns object of action to be dispatched.
 */
export const requestPlpCMSData = (contentOptions) => ({
  type: ListingPageConstants.PLP_CMS_DATA_REQUEST,
  payload: contentOptions,
});

/**
 * Action for setting usp data success
 *
 * @param {string} payload response.
 * @returns {object} usp data success object
 */
export const setUSP = (payload) => ({
  type: ListingPageConstants.SET_PLP_USP,
  payload,
});
/**
 * Action for setting usp data success
 *
 * @param {string} payload response.
 * @returns {object} usp data success object
 */
export const setPlpPromotionalBanners = (payload) => ({
  type: ListingPageConstants.SET_PLP_PROMOTIONAL_BANNERS,
  payload,
});

/**
 * Action for setting PLP long description
 *
 * @param {object} payload PLP CMS long description
 * @returns {object} returns object of action to be dispatched.
 */
export const setLongDescription = (payload) => ({
  type: ListingPageConstants.SET_PLP_LONG_DESCRIPTION,
  payload,
});

/**
 * Action for setting PLP short description
 *
 * @param {object} payload PLP CMS short description
 * @returns {object} returns object of action to be dispatched.
 */
export const setShortDescription = (payload) => ({
  type: ListingPageConstants.SET_PLP_SHORT_DESCRIPTION,
  payload,
});

/**
 * Action for setting product attribute data
 *
 * @param {object} payload contains the headers data and API path.
 * @returns {object} returns object of action to be dispatched.
 */
export const getProductAttributeData = (payload) => ({
  type: ListingPageConstants.GET_PRODUCT_ATTRIBUTE_DATA,
  payload,
});

/**
 *  Action for setting product attribute data
 *
 * @param {object} payload contains the attribute data.
 * @returns {object} returns object of action to be dispatched.
 */
export const setProductAttributeData = (payload) => ({
  type: ListingPageConstants.SET_PRODUCT_ATTRIBUTE_DATA,
  payload,
});

/**
 * Action for setting PLP MicroSite widgets
 *
 * @param {object} payload PLP MicroSite Widgets
 * @returns {object} returns object of action to be dispatched.
 */
export const setPlpMicroSiteWidgets = (payload) => ({
  type: ListingPageConstants.SET_PLP_MICRO_SITE_WIDGETS,
  payload,
});
/**
 * Action for requesting products for sort
 *
 * @returns {object} returns object of action to be dispatched.
 */
export const requestProductsOnPlp = () => ({
  type: ListingPageConstants.REQUEST_PRODUCTS_ON_PLP,
});

/**
 * Action for requesting widget URL from CMS
 *
 * @param {object} categoryOption object container headers and categoryUrl
 * @returns {object} returns object of action to be dispatched.
 */
export const requestWidgetUrlFromCMS = (categoryOption) => ({
  type: ListingPageConstants.GET_WIDGET_DATA,
  payload: categoryOption,
});

/**
 * Action for setting widgets data
 *
 * @param {object} payload  Widgets data
 * @returns {object} returns object of action to be dispatched.
 */
export const setWidgetData = (payload) => ({
  type: ListingPageConstants.SET_WIDGET_DATA,
  payload,
});
/**
 * Action for setting search redirect url
 *
 * @param {object} payload contains search redirect url
 * @returns {object} returns object of action to be dispatched.
 */
export const setSearchRedirectUrl = (payload) => ({
  type: ListingPageConstants.SET_SEARCH_REDIRECT_URL,
  payload,
});
