import { call, takeLatest, select, put, all } from "redux-saga/effects";
import ListingPageConstants from "../../src/action-constants/listing-contants";
import {
  setPlpHTMLData,
  setBannerCategory,
  plpErrorMSG,
  setPLPReviews,
  setProductList,
  setPageName,
  updateEarliestDeliveries,
  setContentIdDetails,
  setProductSummaryDetails,
  setUSP,
  setLongDescription,
  setShortDescription,
  setProductAttributeData,
  setPlpMicroSiteWidgets,
  setPlpListingLoading,
  setWidgetData,
  setSearchRedirectUrl,
  setPlpPromotionalBanners,
  setCapsuleData,
} from "../../src/actions/plp-actions";
import {
  fetchPLPReviews,
  fetchProductListing,
  fetchProductListingOnClient,
  fetchEarliestDeliveries,
  fetchContentSearchedList,
  getProductSummaryDetails,
  fetchEarliestDeliveriesFromR2,
} from "../../src/services/api/plp/product-listing-api";
import { fetchContentFromCMS, getWidgetUrl } from "../../src/services/api/common/common-api";
import {
  getProductListingData,
  getUpdatedProductListingData,
  populateEarliestDeliveries,
  getProductIds,
  getProductIdsForSpotlight,
} from "../../src/utils/plp-helper";
import commonPageSagaActions from "../../src/actions/common-page-saga-actions";

/**
 * action to trigger search instead list
 *
 * @param {string} catalogId - catalog id
 * @param {object} headers headers for fetching cms data
 */
function* requestContentSearchList(catalogId, headers) {
  try {
    // Query Params for search instead
    const contentURL = `global/static/${catalogId}/search-instead`;
    const searchInsteadParam = {
      contentURL,
      headers,
    };
    const res = yield call(fetchContentSearchedList, searchInsteadParam);
    if (res) {
      yield put(setContentIdDetails(res));
    }
  } catch (error) {
    yield put(plpErrorMSG(error));
  }
}

/**
 * this function handles the plp svn data and sets the value in store
 *
 * @param {object} responseData object of plp data
 */
function* handlePlpHTMLData(responseData) {
  try {
    yield put(setPlpHTMLData(responseData));
    yield put(setPageName("plp-micro-site"));
  } catch (error) {
    yield put(plpErrorMSG(error));
  }
}

/**
 * Handles plp reviews
 * It handles for both microsites and PLP
 *
 * @param {object} root .
 * @param {object} root.payload its a object contains catagoryId and domainID
 * customCategoryId is a categoryId for reviews
 * domainId is a domainId for reviews
 */
function* getAndSetPLPReviews({ payload }) {
  const { customCategoryId, domainId } = payload;
  const VIEW_ALL = "view_all";
  const redirectionUrl = `/info/testimonials?pageType=category&categoryId=${customCategoryId}&domainId=${domainId}`;

  try {
    const response = yield call(fetchPLPReviews, { customCategoryId, domainId });
    if (response?.data) {
      const categoryReviews = [...response.data];
      if (response.data.length >= 12) {
        categoryReviews.push({
          categoryId: VIEW_ALL,
          redirectionUrl,
        });
      }
      yield put(setPLPReviews(categoryReviews));
    }
  } catch (error) {
    yield put(plpErrorMSG(error));
  }
}

/**
 * This function handles the product list api and sets the data in reducer accordingly
 *
 * @param {object} action contains the data required for this generator function
 */
function* getCategoryProductsList(action) {
  try {
    const response = yield call(fetchProductListing, action.payload);

    if (response?.data) {
      const payload = yield call(getProductListingData, response, action.payload.options);
      yield all([put(setProductList(payload)), put(setPageName("category"))]);
      if (action.payload.options.params.pageType === "search" && response?.noResultsFound) {
        yield call(requestContentSearchList, action.payload.options.params.geoId, action.payload.ctx?.req.headers);
      } else {
        yield put(setContentIdDetails([]));
      }

      // The code is commented to remove the dependency of category review api on product list api. if something went wrong we will uncomment this change
      // these api call is transferred to listing(for mobile) and desktop-listing to improve seo

      // if (response?.categoryTotalReview > 0) {
      //   const {
      //     categoryId: customCategoryId,
      //     options: {
      //       params: { domainId },
      //     },
      //   } = action.payload;
      //   yield call(getAndSetPLPReviews, { customCategoryId, domainId });
      // }
    } else if (response?.redirectUrl) {
      yield put(setSearchRedirectUrl({ redirectUrl: response?.redirectUrl }));
    } else if (response?.htmlDOM) {
      yield call(handlePlpHTMLData, response);
    } else {
      const ctxRes = action.payload.ctx.res;
      ctxRes.statusCode = response?.errorStatus || 500;
    }
  } catch (error) {
    yield put(plpErrorMSG(error));
  }
}

/**
 * Handles
 *
 * @param {object} action contains type and payload
 */
function* getProductsListOnClient(action) {
  const { options, appendProducts } = action.payload;

  try {
    yield put(setPlpListingLoading(true));
    const response = yield call(fetchProductListingOnClient, options);
    let payload = {};
    if (appendProducts) {
      const productList = yield select((store) => store.productsLists);
      payload = yield call(getUpdatedProductListingData, response, options, productList);
    } else {
      payload = yield call(getProductListingData, response, options);
    }
    yield put(setProductList(payload));
  } catch (err) {
    yield put(plpErrorMSG(ListingPageConstants.PRODUCT_LISTING_LOAD_MORE_FAILED));
  } finally {
    yield put(setPlpListingLoading(false));
  }
}

/**
 * Watcher saga for clientSideProductListing
 *
 * @param {object} action object contains payload
 */
function* handleEarliestDeliveryDates(action) {
  try {
    const { pincode, isR2Call } = action.payload || {};
    const {
      productsLists: { productsList, searchOptions },
      appConfigs: { catalogId },
    } = yield select((state) => state);
    const productsIds = yield call(getProductIds, productsList, searchOptions?.params?.size);
    if (Array.isArray(productsIds) && productsIds.length) {
      let query = `productIds=${encodeURI(productsIds.join(","))}&FNP_CURRENT_CATALOG_ID=${catalogId}`;
      if (pincode) {
        query = `${query}&pincode=${pincode}`;
      }
      const deliveryData = isR2Call
        ? yield call(fetchEarliestDeliveriesFromR2, query)
        : yield call(fetchEarliestDeliveries, query);
      const modifiedEarliestDeliveryDates = yield call(populateEarliestDeliveries, deliveryData.deliveryDates);
      yield put(updateEarliestDeliveries(modifiedEarliestDeliveryDates, deliveryData.deliveryDates));
    }
  } catch (ex) {
    yield put(plpErrorMSG(ex));
  }
}

/**
 * This function returns a product description as per the product ids
 *
 * action object of product ids
 */
function* getProductDesc() {
  try {
    const {
      productsLists: { productsList, page, size },
    } = yield select((state) => state);
    const fetchProductIdsForSpotlight = yield call(getProductIdsForSpotlight, productsList, page, size);
    if (Array.isArray(fetchProductIdsForSpotlight) && fetchProductIdsForSpotlight.length) {
      const data = yield call(getProductSummaryDetails, fetchProductIdsForSpotlight);
      yield put(setProductSummaryDetails(data));
    }
  } catch (error) {
    yield put(plpErrorMSG(error));
  }
}

/**
 * This function handles the cms data of plp page
 *
 * @param {object} action object of content url
 */
function* handlePlpCmsData(action) {
  try {
    const {
      categoryBanner,
      usp,
      longDescription,
      shortDescription,
      trendingNowWidget,
      plpPromotionalBanners,
      capsuleData,
    } = yield call(commonPageSagaActions, action);
    if (categoryBanner) {
      yield put(setBannerCategory(categoryBanner));
    }
    if (usp) {
      yield put(setUSP(usp));
    }
    if (longDescription) {
      yield put(setLongDescription(longDescription));
    }
    if (shortDescription) {
      yield put(setShortDescription(shortDescription));
    }
    if (trendingNowWidget) {
      yield put(setPlpMicroSiteWidgets(trendingNowWidget));
    }
    if (plpPromotionalBanners) {
      yield put(setPlpPromotionalBanners(plpPromotionalBanners));
    }
    if (capsuleData) {
      yield put(setCapsuleData(capsuleData));
    }
  } catch (error) {
    yield put(plpErrorMSG(error));
  }
}

/**
 * This method will calls attribute data API
 *
 * @param {object} action contains the headers data and API path.
 */
function* handleProductAttributeData(action) {
  try {
    const res = yield call(fetchContentFromCMS, action.payload);
    if (res) {
      yield put(setProductAttributeData(res));
    }
  } catch (error) {
    yield put(plpErrorMSG(error));
  }
}

/**
 * This method will calls attribute data API
 *
 * @param {object} action contains the headers data and API path.
 */
function* handleWidgetData(action) {
  try {
    const res = yield call(getWidgetUrl, action.payload);
    if (res) {
      yield put(setWidgetData(res.widgetUrls));
    }
  } catch (error) {
    yield put(plpErrorMSG(error));
  }
}

/**
 * Watcher saga for serverSideProductListing
 */
function* watchPlpSaga() {
  yield takeLatest(ListingPageConstants.GET_PRODUCT_LISTING_SERVER_REQUEST, getCategoryProductsList);
  yield takeLatest(ListingPageConstants.SERVER_REQUEST_PLP_REVIEWS, getAndSetPLPReviews);
  yield takeLatest(ListingPageConstants.REQUEST_GET_PRODUCT_LISTING, getProductsListOnClient);
  yield takeLatest(ListingPageConstants.FETCH_EARLIEST_DELIVERIES, handleEarliestDeliveryDates);
  yield takeLatest(ListingPageConstants.PRODUCT_DESC_REQUEST, getProductDesc);
  yield takeLatest(ListingPageConstants.PLP_CMS_DATA_REQUEST, handlePlpCmsData);
  yield takeLatest(ListingPageConstants.GET_PRODUCT_ATTRIBUTE_DATA, handleProductAttributeData);
  yield takeLatest(ListingPageConstants.GET_WIDGET_DATA, handleWidgetData);
}
export default watchPlpSaga;
