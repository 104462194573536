import qs from "qs";
import getConfig from "next/config";
import nookies from "nookies";
import axios from "../../../../app_configs/axios/base";
import gateway from "../../../../app_configs/axios/gateway";
import errorLogger from "../../../../app_configs/logger-service";
import { getCacheHeaders } from "../../../utils/common";

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
const showRequestLogs = publicRuntimeConfig.SHOW_REQUESTS_LOGS;
const mobileServerControlHost = serverRuntimeConfig.SERVER_CONTROL_HOST;
const desktopServerControlHost = serverRuntimeConfig.D_SERVER_CONTROL_HOST;
const cmsAPIVersion = publicRuntimeConfig.CMS_API_VERSION;
const beautyPlusAPIVersion = publicRuntimeConfig.BEAUTYPLUS_API_VERSION;
const serverLessService = publicRuntimeConfig.SLS_API_VERSION;
const isServer = typeof window === "undefined";
const isDesktop = !isServer ? window.dSite : false;

const error = {
  config: {},
  response: {},
};

/**
 * This function is used to get content id details
 *
 * @param {string} contentId content id.
 * @param {boolean} isMobile if request is from mobile
 * @returns {object} content id details.
 */
export const fetchContentIdDetails = async (contentId, isMobile = true) => {
  const endPoint = "control/getContentIdDetails-rj?contentId";
  try {
    const response = await axios.get(`${endPoint}=${contentId}`, {
      baseURL: isMobile ? mobileServerControlHost : desktopServerControlHost,
    });
    return response.data;
  } catch (err) {
    error.config.url = endPoint;
    error.response.status = `An Exception occurred while calling contentId details API => ${err}`;
    errorLogger(err, showRequestLogs);
    throw new Error(err);
  }
};

/**
 * This function fetches Rel Alts Data for Meta of The Page
 *
 * @param {string} getRelAltDataQueryString rel alt query string
 * @param {boolean} isMobile if request is from mobile
 * @returns {object} rel alternate data on success and empty on exception.
 */
export const fetchRelAltData = async (getRelAltDataQueryString, isMobile = true) => {
  const endPoint = "control/getRelAltData";
  try {
    const response = await axios.get(`${endPoint}?${getRelAltDataQueryString}`, {
      baseURL: isMobile ? mobileServerControlHost : desktopServerControlHost,
    });
    return response.data;
  } catch (err) {
    error.config.url = endPoint;
    error.response.status = `An Exception occurred while calling rel alt API => ${err}`;
    errorLogger(err, showRequestLogs);
    throw new Error(err);
  }
};

/**
 * Method to fetch content from CMS on server side
 *
 * @param {object} options contains cms options
 * @param {string} options.contentURL page content slug
 * @param {object} options.headers page headers
 * @param {object} options.ctx context object
 * @param {boolean} options.setResHeaders to check if we should set cache headers or not
 * @returns {string} returns html file data
 */
export async function fetchContentFromCMS({ contentURL, headers, ctx, setResHeaders = false }) {
  // TODO  // adding  default for header values for x-device-type and x-domain as the code breaks when the value is undefined,as undefined is not accepted as valid header values to pass on to server - to be checked if headers need to be passed or not as gateway already has headers defined
  try {
    const endPoint = cmsAPIVersion + contentURL;
    const response = await gateway.get(endPoint, {
      headers: {
        "x-device-type": headers["x-device-type"] || (isDesktop ? "desktop" : "mobile"),
        "x-domain": headers["x-domain"] || publicRuntimeConfig.DOMAIN,
      },
    });
    if (setResHeaders && response?.headers) {
      const headersToSet = getCacheHeaders(response.headers);
      ctx?.res?.set(headersToSet);
    }
    return Array.isArray(response.data) ? response.data : { ...response.data, status: response.status };
  } catch (err) {
    if (err.response) return { errorStatus: err.response?.status };
    if (err.code) return err.code;
    return err;
  }
}

/**
 * Function to fetch widget content
 *
 * @param {string} productIdsStr product Ids of recently visited products
 * @param {string} catalogId catalogId
 * @returns {object} response data containing delivery dates
 */
export async function fetchEarliestDeliverDates(productIdsStr, catalogId) {
  try {
    const endPoint = `/control/getEarliestDeliveryDays?productIds=${productIdsStr}&FNP_CURRENT_CATALOG_ID=${catalogId}`;
    const response = await axios.get(endPoint);
    return response.data;
  } catch (err) {
    error.response.status = `An Exception occurred while fetching widget content => ${err}`;
    errorLogger(err, showRequestLogs);
    throw new Error(err);
  }
}

/**
 * Method to fetch content from CMS on client side
 *
 * @param {string} contentURL page content slug
 * @returns {string} returns html file data
 */
export async function fetchContentFromCMSOnClient(contentURL) {
  try {
    const endPoint = cmsAPIVersion + contentURL;
    const response = await gateway.get(endPoint);
    return response.data;
  } catch (err) {
    throw new Error(err.message);
  }
}

/**
 * Method to fetch content from CMS on client side
 *
 * contentURL page content slug
 *
 * @returns {string} returns html file data
 */
export async function fetchWidgetHelpersFromCMS() {
  try {
    const serverGatewayURL = serverRuntimeConfig.R2_SERVER_API_GATEWAY;
    const response = await axios.get(`${cmsAPIVersion}widgets/static/widgethelper`, {
      baseURL: serverGatewayURL,
    });
    if (response.data) return response.data;
    return "";
  } catch (err) {
    throw new Error(err.message);
  }
}

/**
 * Method to fetch content from CMS on client side
 *
 * @param {object} params params we need to pass
 * @returns {string} returns html file data
 */
export async function fetchRRConfigContent(params) {
  try {
    // eslint-disable-next-line no-console
    const endPoint = `${beautyPlusAPIVersion}sites/featureConfig/getFeatureStatus`;
    const response = await gateway.get(endPoint, { params });
    return response.data;
  } catch (err) {
    throw new Error(err.message);
  }
}

/**
 * Function to fetch widget content
 *
 * @param {string} widgetUrl widget api url
 * @param {object} params params for the api
 * @returns {object} promise
 */
export async function fetchWidgetContent(widgetUrl, params) {
  try {
    return gateway.get(`${widgetUrl}&${qs.stringify(params, { arrayFormat: "repeat", encode: false })}`);
  } catch (err) {
    error.response.status = `An Exception occurred while fetching widget content => ${err}`;
    errorLogger(err, showRequestLogs);
    throw new Error(err);
  }
}

/**
 * Method to fetch content from CMS
 *
 * @param {object} categoryOption object containing categoryUrl and headers
 * @returns {object} response
 */
export async function getWidgetUrl(categoryOption) {
  const endPoint = `${cmsAPIVersion}widgetscat/${categoryOption.categoryUrl}-widgets`;
  try {
    const response = await gateway.get(endPoint, {
      headers: {
        "x-device-type": categoryOption.headers["x-device-type"] || (isDesktop ? "desktop" : "mobile"),
        "x-domain": categoryOption.headers["x-domain"],
        "accept-language": "en",
      },
    });
    return response.data;
  } catch (err) {
    error.config.url = endPoint;
    error.response.status = `An Exception occurred while fetching widget urls => ${err}`;
    errorLogger(err, showRequestLogs);
    throw new Error(err);
  }
}
/**
 *Method to fetch the getSystemPropertyValue for impression
 *
 *@param {object} ctx this page related data for serverside
 *@param {boolean} isMobile getting boolean value
 */
export async function timeIntervalForImpAndClickEvTracking(ctx, isMobile = true) {
  let impressionDelayTimeConstant = 2000;
  try {
    const response = await axios.get(
      "/control/getSystemPropertyValue?resource=fnp&name=timeIntervalForImpAndClickEvTracking",
      {
        baseURL: isMobile ? mobileServerControlHost : desktopServerControlHost,
      },
    );
    /**
     * Here we check if API has below mention data :
     * 1.Not any blank space
     * 2.Not greater then 3000 miliseconds
     * 3.Not less then 1000 miliseconds
     */
    if (response?.data?.value?.trim() && Number(response.data.value) <= 5000 && Number(response.data.value) >= 1000) {
      impressionDelayTimeConstant = response.data.value;
    }
    nookies.set(ctx, "impressionDelayTime", impressionDelayTimeConstant, {
      path: "/",
    });
  } catch (err) {
    errorLogger(err, showRequestLogs);
    throw new Error(err);
  }
}

/**
 * this API used for post data in cleverTap.
 *
 * @param {object} payload - object you need to send in cleverTap
 * @returns {object} - returns response object or error object.
 */
export async function publishCleverTapData(payload) {
  try {
    return await gateway.post(`${serverLessService}/publish`, payload);
  } catch (err) {
    error.config.url = `${serverLessService}/publish`;
    error.response.status = `An Exception occurred while posting a cleverTap data => ${err}`;
    errorLogger(err, showRequestLogs);
    throw new Error(err);
  }
}
