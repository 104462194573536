import { call, put } from "redux-saga/effects";
import { fetchContentFromCMS } from "../services/api/common/common-api";
import { errorInMicroSitePage } from "./cms-microSite-actions";
import {
  setCanonicalData,
  setOgTagData,
  setPageBodyAnalyticsData,
  setPageBottomAnalyticsData,
  setPageBreadcrumbsData,
  setPageFooterData,
  setPageHeadAnalyticsData,
  setPageMetaData,
  setPageRelAltData,
  setSchemaTagData,
  setTwitterTagData,
  setPagePreloadData,
  setCapsuleData,
} from "./common-page-actions";

/**
 * This is a worker saga function that calls the get content for svn returns the data
 * It also sets some common content in the reducer
 *
 * @param {object} action - contains the response payload information of svn content
 * @returns {object} html string and on error empty string
 */
function* commonPageSagaActions(action) {
  try {
    const cmsResponse = yield call(fetchContentFromCMS, action.payload);
    const {
      metaContent,
      canonical,
      ogTag,
      twitterTag,
      schemaTag,
      relAlt,
      mainContent,
      breadcrumbs,
      headAnalytics,
      bodyAnalytics,
      bottomAnalytics,
      footer,
      trendingNowWidget,
      footerSEOContent,
      categoryBanner,
      usp,
      longDescription,
      shortDescription,
      categoryBreadCrumbs,
      errorStatus = 500,
      status,
      triggerGateLock,
      preLoad,
      plpPromotionalBanners,
      capsuleData,
    } = cmsResponse;

    if (status !== 200) {
      throw new Error(errorStatus);
    }

    yield put(setPageMetaData(metaContent));
    yield put(setCanonicalData(canonical));
    yield put(setOgTagData(ogTag));
    yield put(setTwitterTagData(twitterTag));
    yield put(setSchemaTagData(schemaTag));
    yield put(setPageRelAltData(relAlt));
    yield put(setPageBreadcrumbsData(breadcrumbs || categoryBreadCrumbs));
    yield put(setCapsuleData(capsuleData));
    yield put(setPageHeadAnalyticsData(headAnalytics));
    yield put(setPageBodyAnalyticsData(bodyAnalytics));
    yield put(setPageBottomAnalyticsData(bottomAnalytics));
    yield put(setPageFooterData(footer));
    yield put(setPagePreloadData(preLoad));
    return {
      mainContent,
      trendingNowWidget,
      footerSEOContent,
      footer,
      categoryBanner,
      usp,
      longDescription,
      shortDescription,
      triggerGateLock,
      plpPromotionalBanners,
      capsuleData,
    };
  } catch (error) {
    yield put(errorInMicroSitePage(error.message));
    return {
      errorStatus: Number(error.message),
    };
  }
}

export default commonPageSagaActions;
